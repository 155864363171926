var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseForm',{ref:"formEl",attrs:{"model":_vm.form,"rules":_vm.rules,"label-position":"top"}},[_c('fieldset',[_c('legend',{staticClass:"text-md font-bold uppercase mb-2.5 lg:mb-5",class:{
        ' w-full border-b-2 border-b-pfm-grey-400 pl-4 py-2':
          _vm.layout === 'horizontal',
      }},[_vm._v("\n      "+_vm._s(_vm.$t('login.existing'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"w-full",class:{
        'md:flex md:items-end md:space-x-4': _vm.layout === 'horizontal',
      }},[_c('div',{staticClass:"flex-1"},[_c('BaseFormItem',{staticClass:"mb-2.5 lg:mb-5",attrs:{"prop":"email","label-for":"login-email","label":_vm.$t('userInfo.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var status = ref.status;
      var touch = ref.touch;
return [_c('div',{staticClass:"h-10"},[_c('BaseInput',{attrs:{"id":"login-email","type":"email","size":"free","placeholder":_vm.$t('userInfo.email'),"status":status,"data-testid":"form-login-email-address-input"},on:{"change":touch},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]}}])}),_vm._v(" "),_c('BaseFormItem',{attrs:{"prop":"password","label-for":"login-password","label":_vm.$t('userInfo.password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var status = ref.status;
      var touch = ref.touch;
return [_c('div',{staticClass:"h-10"},[_c('BaseInput',{attrs:{"id":"login-password","type":"password","size":"free","placeholder":_vm.$t('userInfo.password'),"status":status,"data-testid":"form-login-passward-input"},on:{"change":touch,"enter":_vm.onSubmit},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)]}}])}),_vm._v(" "),_c('AppNoty',{staticClass:"mt-5",attrs:{"show":_vm.isLoginFail,"text":_vm.loginFailMessage,"status":"error"},on:{"update:show":function($event){_vm.isLoginFail=$event}}})],1),_vm._v(" "),_c('div',{staticClass:"mt-5 flex-1"},[_c('NuxtLink',{staticClass:"underline text-sm inline-block mb-3 ml-3",attrs:{"to":_vm.localePath({ name: 'forgot-password' }),"data-testid":"forgot-passward-link"}},[_c('span',[_vm._v(_vm._s(_vm.$t('login.forgotPassword')))])]),_vm._v(" "),_c('div',{staticClass:"h-10"},[_c('BaseButton',{staticClass:"px-4",attrs:{"disabled":_vm.loginLoading,"loading":_vm.loginLoading,"size":"free","block":"","data-testid":"form-login-btn"},on:{"click":_vm.onSubmit}},[_vm._v("\n            "+_vm._s(_vm.$t('common.text.login'))+"\n          ")])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'lg:flex lg:items-start': _vm.labelPosition === 'right',
  }},[(_vm.label || _vm.slots.label)?_c('div',{class:{
      'lg:flex lg:items-center lg:justify-end lg:w-[130px] lg:min-h-10 font-bold lg:font-normal lg:pr-4 ':
        _vm.labelPosition === 'right',
    }},[_vm._t("label",function(){return [_c('label',{staticClass:"block first-letter:capitalize leading-tight line-clamp-2 font-bold pl-3 pb-1",class:{
          'lg:text-right': _vm.labelPosition === 'right',
        },attrs:{"for":_vm.labelFor || _vm.prop}},[_vm._v("\n        "+_vm._s(_vm.finalRequired ? (_vm.label + "*") : _vm.label)+"\n      ")])]},null,{ required: _vm.finalRequired })],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"lg:flex-1 relative"},[_vm._t("default",null,null,{
        status: _vm.status,
        touch: _vm.touch,
      }),_vm._v(" "),(_vm.errorMessage)?_c('p',{staticClass:"text-pfm-error leading-none first-letter:capitalize py-1 m-0"},[_vm._t("error",function(){return [_vm._v("\n        "+_vm._s(_vm.errorMessage.$message)+"\n      ")]},null,{ errorMessage: _vm.errorMessage })],2):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }